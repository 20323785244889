<template>
  <div>
    <v-bottom-navigation color="info" horizontal :background-color="color" dark>
      <v-btn to="/vecprovince/commiteevecpro">
        <span>แต่งตั้งคณะกรรมการตรวจสอบ </span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>

      <v-btn to="/vecprovince/transference_personnel">
        <span>ผู้เสนอย้าย ตำแหน่งครู</span>
        <v-icon>mdi-source-branch</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย สายการสอน"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <!--  {{ checkEventAss }} -->
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="6">
              <h2
                v-if="commiteevecreports.commiteevecreportStatus !== '1'"
                class="red--text"
              >
                กรุณาแต่งตั้งคณะกรรมการตรวจสอบ หรือ กดยืนยันรายชื่อกรรมการ
              </h2>
              <v-btn
                v-if="commiteevecreports.commiteevecreportStatus === '1'"
                block
                rounded
                color="info"
                :href="
                  '#/vecprovince/print_kpi_teacher_vecp/' + user.province_ID
                "
                target="_blank"
                ><v-icon class="pr-2">mdi-printer</v-icon> 1. แบบบันทึกคะแนน
                ตัวชี้วัด</v-btn
              >
              <v-btn
                v-if="commiteevecreports.commiteevecreportStatus === '1'"
                block
                rounded
                color="info"
                :href="
                  '#/vecprovince/print_kpi_teacher_vecp_all/' + user.province_ID
                "
                target="_blank"
                ><v-icon class="pr-2">mdi-printer</v-icon> 2.
                แบบบันทึกผลรวมคะแนน ตัวชี้วัด</v-btn
              >
            </v-col>
            <!--  <v-col cols="4">
              <v-btn block rounded color="info"
                ><v-icon class="pr-2">mdi-printer</v-icon>3. แบบบันทึกคะแนน กรรมการรวม</v-btn
              >
              <v-btn block rounded color="info"
                ><v-icon class="pr-2">mdi-printer</v-icon
                >4. แบบบันทึกสรุปผลคะแนนกรรมการรวม</v-btn
              >
            </v-col> -->
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="transference_personnels"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{ index + 1 }}</td>
              <!--    <td class="text-center">{{ item.tid_ref }}</td> -->
              <td class="text-center">{{ item.id_card }}</td>
              <td class="text-center">{{ item.frist_name }}</td>
              <td class="text-center">{{ item.last_name }}</td>
              <td class="text-center">{{ item.college_name }}</td>
              <td class="text-center">{{ item.time_ss }}</td>
              <td class="text-center">{{ item.year_ss }}</td>
              <td class="text-center">{{ item.age_app_time }}</td>
              <!--   <td class="text-center">
                {{
                  item.date_time
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </td> -->
              <td class="text-center">
                <div v-if="item.documentsLinkfile">
                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    @click="viewdocumentsLinkfile(item.documentsLinkfile)"
                    ><v-icon large>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td>
              <!--  <td class="text-center">
                <div v-if="item.doccon_1">
                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    :href="'/HRvecfiles/' + item.doccon_1"
                    target="_blank"
                    ><v-icon large>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td> -->
              <td class="text-center">
                <div v-if="commiteevecreports.commiteevecreportStatus === '1'">
                  <v-chip
                    v-if="item.tpvecprovince_scoreFile"
                    color="green"
                    dark
                    >{{ item.tpvecprovince_score }}</v-chip
                  >
                  <v-icon
                    large
                    v-else
                    color="green"
                    @click="comment_idPosition(item.tid_ref)"
                    >mdi-plus-box</v-icon
                  >
                </div>
              </td>
              <td class="text-center">
                <div v-if="item.tpvecprovince_scoreFile">
                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    @click="viewdocumentsLinkfile(item.documentsLinkfile)"
                    ><v-icon large>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td>
              <td class="text-center">
                <div v-if="item.tpvecprovince_scoreFile">
                  <v-chip
                    color="red"
                    dark
                    v-if="item.ccDocumentFilePVC === 'cc'"
                    >รอการยกเลิก</v-chip
                  >
                  <v-btn
                    rounded
                    v-else
                    color="warning"
                    @click="cancelPDFSubmit(item.tid_ref)"
                  >
                    <v-icon color="red" class="pr-1">mdi-close</v-icon>
                    ยกเลิกแนบไฟล์</v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    <v-icon>mdi-information</v-icon> ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model edittransference_personneldialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="edittransference_personneldialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="edittransference_personnelform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      {{ edittransference_personnel.id_card }}
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Password"
                        v-model="edittransference_personnel.p_word"
                        type="password"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Confirm Password"
                        v-model="
                          edittransference_personnel.transference_personnel_confirmpassword
                        "
                        type="password"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="edittransference_personneldialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="edittransference_personnelSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model commentDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="commentDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความผลการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ transference_personnels_id_ref.year_ss }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        type="number"
                        outlined
                        label="คะแนนประเมินจากคณะกรรมการ"
                        prepend-icon="mdi-account-check"
                        v-model="
                          transference_personnels_id_ref.tpvecprovince_score
                        "
                        required
                        :rules="[v => !!v || '', v => v <= 60, v => v != 0]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="7">
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="เอกสารประเมิน .pdf"
                        placeholder="เพิ่มไฟล์เอกสารประเมิน .pdf"
                        outlined
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <span class="red--text"
                >* เมื่อกดปุ่มบันทึกแล้วไม่สามารถแก้ไขข้อมูลได้
                กรุณาตรวจสอบข้อมูลให้ถูกต้อง</span
              >
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="commentDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecommentSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model transference_personnelsCollegePDFCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="transference_personnelsCollegePDFCCdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกแนบไฟล์ เนื่องจากไฟล์ไม่สมบูรณ์หรือแนบผิด"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnelsCollegePDFCC.tid_ref }}
                        {{ transference_personnelsCollegePDFCC.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnelsCollegePDFCC.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnelsCollegePDFCC.title_s
                        }}{{ transference_personnelsCollegePDFCC.frist_name }}
                        {{ transference_personnelsCollegePDFCC.last_name }}
                        <br />
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnelsCollegePDFCC.college_code }}
                        {{ transference_personnelsCollegePDFCC.college_name }}
                        <br />
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnelsCollegePDFCC.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnelsCollegePDFCC.time_s }} ปี :
                        {{ transference_personnelsCollegePDFCC.year_s }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                fab
                text
                large
                @click.stop="transference_personnelsCollegePDFCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="cancelPDFCCSubmitConfirm()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon
                >&nbsp;ยืนยันขอยกเลิกแนบไฟล์
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfdocumentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfdocumentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      edittransference_personneldialog: false,
      transference_personnelsCollegePDFCCdialog: false,

      times_select: "1",
      documentsLinkfiles: null,
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_personnels: [],
      edittransference_personnel: {},
      search: "",
      pagination: {},
      headers: [
        { text: "#", align: "center", value: "index" },
        /*  { text: "อ้างอิง", align: "center", value: "tid_ref" }, */
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "วิทยาลัย", align: "center", value: "college_name" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        /*    { text: "วันที่ทำรายการ", align: "center", value: "date_time" }, */
        {
          text: "แบบคำร้องขอย้าย",
          align: "center",
          value: "documentsLinkfile"
        },
        /*  { text: "เอกประกอบการพิจารณา", align: "center", value: "doccon_1" }, */
        {
          text: "ผลการพิจารณา",
          align: "center",
          value: "actions"
        },
        { text: "เอกสารแนบ", align: "center", value: "documents" },
        { text: "ขอยกเลิกเอกสารแนบ", align: "center", value: "cancelPDF" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_personnelstatus: [],
      userstatus: {},
      commentDrdialog: false,
      transference_personnels_id_ref: [],
      regions: [],
      periods: [],
      period_enable: "1",
      updatecomment: {},
      branch_s: [],
      branch_sub_ds_select: [],
      branchs_id_sub: [],
      period_vecprovince_file: [],
      period_vecprovince_enfile: "1",
      user: [],
      commiteevecreports: [],
      transference_personnelsCollegePDFCC: [],
      pdfdocumentsLinkfileDialog: false,
      pdf_files: [],
      linealerts: {}
    };
  },
  async mounted() {
    await this.userQuery();
    await this.periodQuery();
    await this.branchQuery();
    await this.period_vecprovince_fileQuery();
  },
  methods: {
    async viewdocumentsLinkfile(documentsLinkfile) {
      this.pdf_files = documentsLinkfile;
      this.pdfdocumentsLinkfileDialog = true;
    },

    async branchQuery() {
      let result_branch;
      result_branch = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch_s = result_branch.data;
    },
    async period_vecprovince_fileQuery() {
      let result_period_vecprovince_file = await this.$http.post(
        "period_vecprovince.php",
        {
          ApiKey: this.ApiKey,
          period_vecprovince_enfile: "1",
          period_vecprovinc_id: "301"
        }
      );
      this.period_vecprovince_file = result_period_vecprovince_file.data;
    },
    async userQuery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        provice: "check",
        ApiKey: "HRvec2021"
      });
      this.user = result.data;   
    },
    async cancelPDFCCSubmitConfirm() {
      this.transference_personnelsCollegePDFCC.ApiKey = this.ApiKey;
      this.transference_personnelsCollegePDFCC.ccDocumentFilePVC = "cc";
      let result = await this.$http.post(
        "transference_personnel.update.ccpvc.php",
        this.transference_personnelsCollegePDFCC
      );

      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.linealerts.ApiKey = this.ApiKey;
        this.linealerts.college_name =
          this.user.user_firstname + "." + this.user.user_lastname;
        this.linealerts.details =
          "ย้ายครู : ขอยกเลิกแนบไฟล์คะแนน : " +
          this.transference_personnelsCollegePDFCC.title_s +
          this.transference_personnelsCollegePDFCC.frist_name +
          " " +
          this.transference_personnelsCollegePDFCC.last_name;
        this.linealerts.datetime = this.date_today;

        let result_line = await this.$http.post(
          "linenotify_hr.php",
          this.linealerts
        );
        
        this.transference_personnelQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.transference_personnelsCollegePDFCCdialog = false;
    },
    async cancelPDFSubmit(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnelsCollegePDFCC = result_con.data;
      this.transference_personnelsCollegePDFCCdialog = true;
    },

    async commiteevecreportQuery() {
      let result;
      result = await this.$http.post("commiteevecreport.php", {
        ApiKey: this.ApiKey,
        commiteevecreportproviceID: this.user.province_ID,
        commiteevecreportYear: this.periods.period_vecprovince_year,
        commiteevecreportTime: this.periods.period_vecprovince_times
      });
      this.commiteevecreports = result.data;

   
    },

    async branch_sub_d_select() {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: this.transference_personnels_id_ref.id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds_select = result.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovinc_id: "301"
      });
      this.periods = result_period.data;
      if (this.periods.period_vecprovince_enable != "1") {
        Swal.fire({
          icon: "warning",
          title: "ไม่ถึงกำหนดการเปิดระบบ",
          showConfirmButton: false,
          timer: 3000
        });
        this.$router.push("/vecprovince");
      } else {
        await this.commiteevecreportQuery();
        await this.transference_personnelQueryAll();
      }
    },

    async comment_idPosition(tid_ref) {
      if (this.period_vecprovince_file.period_vecprovince_enfile == "1") {
        let result_con = await this.$http.post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          id_ref: tid_ref
        });
        this.transference_personnels_id_ref = result_con.data;
        this.documentsLinkfiles = null;
        this.commentDrdialog = true;   
      } else {
        Swal.fire({
          icon: "warning",
          title: "ขณะนี้ได้ปิดระบบรับรายงานข้อมูล",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async transference_personnelQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          province_ID: userSession.province_ID,
          time_s: this.periods.period_vecprovince_times,
          year_s: this.periods.period_vecprovince_year,
          type_move: "nm"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_personnels_id_ref.id_tfp;
        this.updatecomment.tpvecprovince_score = this.transference_personnels_id_ref.tpvecprovince_score;
        this.updatecomment.tpvecprovince_scoreDateTime = this.date_today;
        let result = "";
        let uploaded = null;
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnels_id_ref.tid_ref +
            "" +
            this.time_stamp +
            "" +
            "committeevp.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.tpvecprovince_scoreFile = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatecomment
        );

        if (result.data.status == true || uploaded) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
    }
  },
  computed: {
    checkEventAss() {
      let data = this.transference_personnels;
      let sum = 0;
      data.forEach(value => {
        sum = sum + 1;
      });
      return sum;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    color() {
      return "#573005";
    }
  }
};
</script>
